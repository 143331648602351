.img-resp {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

a {
  color: inherit;
}

.upcase {
  text-transform: uppercase;
}

a:hover {
  text-decoration: none;
  color: initial;
}

.small-text{
  font-size: small;
}

.large-text{
  font-size: large;
}

.center_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_content_hori {
  display: flex;
  justify-content: center;
}

.titres-sitesB-mobile {
  font-size: 6vw;
  font-family: "Bree Serif", serif;
  color: #fff;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

.titres-sitesB {
  font-size: 2.5vw;
  font-family: "Bree Serif", serif;
  color: #fff;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

.center-link {
  display: block;
  text-align: center;
}

.responsive_img {
  width: 100%;
  height: auto;
}

body {
  font-size: 0.8vw;
  font-family: "PlutoRegular";
  font-style: normal;
  color: #36393d;
  line-height: 1.1;
}

.responsive_img1 {
  width: auto;
  height: 48vh;
  float: left;
}

.logo {
  width: calc(12 * (1vw + 1vh - 1vmin));
  height: auto;
}

.logo-fixed {
  width: calc(8 * (1vw + 1vh - 1vmin));
  height: auto;
}

.mb-65 {
  margin-bottom: 6rem !important;
}

/* 9 line ... */
.texte-suite-9 {
  overflow: auto;
  height: 14vmax;
}
/* .texte-suite-9::-webkit-scrollbar { width: 0 !important }
.texte-suite-9 { overflow: -moz-scrollbars-none; }
.texte-suite-9 { -ms-overflow-style: none; } */
.texte-suite-9::-webkit-scrollbar {
  width: 5px;
}
 
.texte-suite-9::-webkit-scrollbar-thumb {
  background: #666; 
}


/* .texte-suite-9 {
  position: relative;
  height: 15.1em; 
  overflow: hidden;
}
@media (max-width: 768px) {
  .texte-suite-9 {
    height: 14.9em; 
  }
} */

/* .texte-suite-9:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.2em;
  background: linear-gradient(to right, #ffffffaa, #ffffff 50%);
} */

/* @supports (-webkit-line-clamp: 9) {
  .texte-suite-9 {
    -webkit-line-clamp: 9;
    -moz-line-clamp: 9;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: initial;
    height: initial;
  }
  .texte-suite-9:after {
    display: none;
  }
} */

/* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .texte-suite-9 {
    display: block;
  }
} */

/* end 9 line ... */

/* 4 line ... */

.texte-suite-4 {
  position: relative;
  height: 7.2em; /* exactly three lines */
  overflow: hidden;
}
.texte-suite-4:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.2em;
  background: linear-gradient(to right, #1c26367f, #1c2636 50%);
}

@supports (-webkit-line-clamp: 4) {
  .texte-suite-4 {
    -webkit-line-clamp: 4;
    -moz-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: initial;
    height: initial;
  }
  .texte-suite-4:after {
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .texte-suite-4 {
    display: block;
  }
}

/* end 4 line ... */

/* 2 line ... */

.texte-suite-2 {
  position: relative;
  height: 2.6em; /* exactly three lines */
  overflow: hidden;
}
.texte-suite-2:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.2em;
  background: linear-gradient(to right, #354765aa, #354765 50%);
}

@supports (-webkit-line-clamp: 2) {
  .texte-suite-2 {
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: initial;
    height: initial;
  }
  .texte-suite-2:after {
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .texte-suite-2 {
    display: block;
  }
}

/* end 2 line ... */

/* 3 line ... */

.texte-suite-3 {
  position: relative;
  height: 4.3em; /* exactly three lines */
  overflow: hidden;
}
.texte-suite-3:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.2em;
  background: linear-gradient(to right, #ede6d6aa, #ede6d6aa 50%);
}

@supports (-webkit-line-clamp: 3) {
  .texte-suite-3 {
    -webkit-line-clamp: 3;
    -moz-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: initial;
    height: initial;
  }
  .texte-suite-3:after {
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .texte-suite-3 {
    display: block;
  }
}

/* end 3 line ... */

.rond-menu {
  width: 3vh;
}
.button-vert {
  background-color: #44af8e;
  height: calc(2 * (1vw + 1vh - 1vmin));
  border-radius: 50px;
}

a {
  text-decoration: none;
}

.button-vert:hover {
  -webkit-box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  transform: scale(1.1);
}

.text-buttons {
  font-size: 0.65vw;
  font-family: "PlutoRegular";
  color: #fff;
}

.line {
  line-height: 1.2;
}

.background-header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(/castelnaudary/img/header.png);
  height: 85vh;
}

.texts-sites {
  line-height: 1.8;
  font-size: 0.9vw;
  font-family: "PlutoRegular";
  color: #6c6d6f;
}

.carousel-indicators li {
  width: 0.9vmin;
  height: 0.9vmin;
  border-radius: 100%;
  margin: 12px;
  box-shadow: 3px 2px 7px 0px rgba(0, 0, 0, 0.6);
  border-top: 0;
  border-bottom: 0;
}
.carousel-control-next,
.carousel-control-prev {
  z-index: 15;
  opacity: 1;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  /* height: calc(1 * (1vw + 1vh - 1vmin)); */
  width: calc(1 * (1vw + 1vh - 1vmin));
}

.padding-content-center {
  padding-left: 15vmax !important;
  padding-right: 15vmax !important;
}

@media (max-width: 583px) {
  .padding-content-center {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* #carouselHeader .carousel-indicators {
  bottom: 24vmin;
} */
.carousel-caption {
  right: 0;
  left: 0;
  text-align: left;
  bottom: 0px;
}
.block-header {
  position: relative;
  z-index: 10;
  top: -24vmin;
}
.carousel-title {
  font-size: 2.9vw;
  font-family: "Bree Serif", serif;
  color: #fff;
  /* letter-spacing:.1vw;
  word-spacing: .3vw; */
}

.feuilleF::after {
  background-size: calc(3 * (1vw + 1vh - 1vmin)) calc(2 * (1vw + 1vh - 1vmin));
  background-repeat: no-repeat;
  content: "";
  width: calc(3 * (1vw + 1vh - 1vmin));
  height: calc(2 * (1vw + 1vh - 1vmin));
  position: absolute;
  top: calc(-0.7 * (1vw + 1vh - 1vmin));
}
.form-info {
  font-size: 0.9vw;
  font-family: "PlutoRegular";
  color: #fff;
}

.adroit {
  float: right;
}

.agauche {
  float: left;
}

.feuille {
  width: calc(3 * (1vw + 1vh - 1vmin));
  margin-left: -1rem !important;
}

.back-bleu {
  background-color: rgba(35, 47, 65, 0.7);
}

.back-gris {
  background-color: rgba(52, 55, 59, 0.527);
}

.coclicos {
  width: calc(8 * (1vw + 1vh - 1vmin));
}

.titres-sites {
  font-size: 2.9vw;
  font-family: "Bree Serif", serif;
  color: #354765;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

.mt-7 {
  margin-top: 8rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}
.mt7 {
  margin-top: -5rem !important;
}

.icon-campus {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.titres-sitesB-mobile {
  font-size: 6vw;
  font-family: "Bree Serif", serif;
  color: #fff;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

.titres-sitesB {
  font-size: 2.9vw;
  font-family: "Bree Serif", serif;
  color: #fff;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

.titre-icons {
  font-size: 1.27vw;
  font-family: "PlutoRegular";
  color: #282828;
}

.ourHr {
  background-color: #afb0b1;
  height: 1px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-left: auto;
  margin-right: auto;
}

.card {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.card-body p {
  margin-bottom: 0.5rem;
}

.mb-6 {
  margin-bottom: 4rem !important;
}
.mb-7 {
  margin-bottom: 10rem !important;
}
/* mobile */

.logo-mobile {
  width: 37vmin;
  height: auto;
}

#carouselHeader-mobile .card-img-top {
  /* width: 130vh; */
  height: 65vh;
}

.back-bleu-mobile {
  background-color: rgba(28, 26, 36, 0.5);
}

@media (max-width: 768px) {
  #carouselHeader-mobile .d-md-block {
    display: block !important;
  }
}

.formation-mobile {
  font-size: 8vw;
  font-family: "Bree Serif", serif;
  color: #fff;
  /* letter-spacing:.1vw;
  word-spacing: .3vw; */
}

.button-vert-mobile {
  background-color: #44af8e;
  height: 7.5vmin;
  border-radius: 50px;
}
.button-vert-mobile a {
  text-decoration: none;
}

.form-info-mobile {
  font-size: 3vw;
  font-family: "PlutoRegular";
  color: #fff;
  letter-spacing: 0.2vw;
  word-spacing: 0.3vw;
  /* line-height: 1.9; */
}

.arbres-mobile {
  width: 100%;
  /* height: 54vw; */
}
.titres-sites-mobile {
  font-size: 6vw;
  font-family: "Bree Serif", serif;
  color: #354765;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

.texts-sites-mobile {
  /* line-height: 1.8; */
  /* font-size: calc(3 * (1vw + 1vh - 1vmin)); */
  font-size: 3vw;
  font-family: "PlutoRegular";
  color: #6c6d6f;
}

@media (max-width: 1024px) {
  .texts-sites {
    font-size: 2vmin;
  }
}

.text-buttons-mobile {
  font-size: 3vw;
  font-family: "PlutoRegular";
  color: #fff;
}

.flex-1 {
  flex: 1;
}

.flex-13 {
  flex: 13;
}
.home-header-size {
  height: calc(45 * (1vw + 1vh - 1vmin));
}
.home-header-size-mobile {
  height: calc(75 * (1vw + 1vh - 1vmin));
}
.home-arbres-size {
  height: calc(30 * (1vw + 1vh - 1vmin));
}
.home-arbres-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

.arbres {
  width: auto;
  height: 28vw;
  float: right;
}

.carousel-image-bg {
  background-image: url("/castelnaudary/img/header.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 127vw;
}

.carousel-title-mobile {
  font-size: 10vmin;
  font-family: "Bree Serif", serif;
  color: #fff;
}

.filter-container {
  position: absolute;
  right: 0;
  margin-top: calc(1 * (1vw + 1vh - 1vmin)) !important;
  z-index: 100;
}

.filter-container .filter-open {
  background-color: rgba(68, 175, 142, 0.74);
  border-radius: 40% 0 0 40%;
  cursor: pointer;
}

.filter-container .filter-left-open {
  background-color: #e3d9c0;
  border-radius: calc(3 * (1vw + 1vh - 1vmin)) 0 0 calc(3 * (1vw + 1vh - 1vmin));
}

.filter-container .filter-picto {
  background-color: rgba(68, 175, 142, 0.74);
}

.filter-container .filter-open img {
  height: calc(1 * (1vw + 1vh - 1vmin));
}

.filter-container .hide-filter {
  display: none;
}

.filter-container .filter-picto img {
  height: calc(1 * (1vw + 1vh - 1vmin));
}

.filter-container .filter {
  background-color: #ffffff;
  width: calc(20 * (1vw + 1vh - 1vmin));
}

.filter-container .filter-texte {
  font-size: 1.2vw;
  font-family: "Bree Serif", serif;
  color: #354765;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

.filter-container .hr-container {
  width: calc(12 * (1vw + 1vh - 1vmin));
  margin-left: auto;
  margin-right: auto;
}

.filter-container [type="radio"]:checked,
.filter-container [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.filter-container [type="radio"]:checked + label,
.filter-container [type="radio"]:not(:checked) + label {
  padding-left: 3vw;
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.filter-container [type="radio"]:checked + label:before,
.filter-container [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 0.4px solid #fff;
  border-radius: 100%;
  background: #eee;
}
.filter-container .radio input[type="radio"]:checked + .radio-label:before {
  background-color: #44af8e;
  box-shadow: inset 0 0 0 3.5px #eee;
  color: #36393d;
  font-family: "PlutoBold";
}
.filter-container .radio input[type="radio"]:checked + .radio-label {
  color: #36393d;
  font-family: "PlutoBold";
}
.filter-container .radio input[type="radio"]:checked + .radio-label-mobile {
  color: #36393d;
  font-family: "PlutoBold";
}
.filter-container
  .radio
  input[type="radio"]:checked
  + .radio-label-mobile:before {
  background-color: #44af8e;
  box-shadow: inset 0 0 0 3px #fff;
}
.filter-container [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.filter-container [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 768px) {
  .filter-container [type="radio"]:checked + label,
  .filter-container [type="radio"]:not(:checked) + label {
    padding-left: 8vw;
    font-size: calc(1.5 * (1vw + 1vh - 1vmin));
  }

  .filter-container .filter-open img {
    height: calc(4 * (1vw + 1vh - 1vmin));
  }

  .filter-container .filter-picto img {
    height: calc(4 * (1vw + 1vh - 1vmin));
  }

  .filter-container .filter {
    width: calc(34 * (1vw + 1vh - 1vmin));
  }

  .filter-container .filter-texte {
    font-size: 3.2vw;
  }

  .filter-container .hr-container {
    width: calc(18 * (1vw + 1vh - 1vmin));
  }

  .filter-container .text-buttons {
    font-size: 3vw;
  }
}

/* responsives */

.display-min {
  display: none;
}

@media (max-width: 768px) {
  .display-max {
    display: none;
  }

  .display-min {
    display: flex;
  }

  .display-block {
    display: block;
  }
  .titres-sites {
    font-size: 6vw;
    line-height: 1;
  }

  .b-border::before {
    width: 35%;
  }

  .carousel-indicators li {
    width: 2vmin;
    height: 2vmin;
    margin-bottom: -1vh;
  }
}


.mec-calendar-row dt:nth-child(7) .event-single-link-novel{
  display: none;
}

.mec-calendar-row dt:nth-child(6) .event-single-link-novel{
  display: none;
}

.spinner-container{
  position: fixed;
  z-index: 10000;
  background: #5a5a5a6b;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.nf-field-element ul{
  display: flex;
  justify-content: space-between!important;
}
#menu-module .b-menu-border {
  border-bottom: 2px solid #e9e1cc !important;
}

#menu-module .button-vert-menu {
  background-color: #44af8e;
  height: calc(2 * (1vw + 1vh - 1vmin));
  border-radius: 50px;
}

#menu-module .menu-container {
  overflow-y: scroll;
}

#menu-module .button-vert-menu:hover {
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  transform: scale(1.1);
}

#menu-module .text-buttons-menu {
  font-size: 0.9vw;
  font-family: "PlutoRegular";
  color: #fff;
}

#menu-module .text-buttons-menu-mobile {
  font-size: 3.9vw;
  font-family: "PlutoRegular";
  color: #fff;
}

#menu-module .search-menu-desktop {
  display: none;
  height: calc(2 * (1vw + 1vh - 1vmin));
}

#menu-module .search-menu-desktop .btn-outline-secondary {
  font-size: calc(1 * (1vw + 1vh - 1vmin));
}

#menu-module .search-menu-desktop .search-input {
  font-size: calc(1 * (1vw + 1vh - 1vmin));
}

#menu-module .serch-menu-container:hover {
  transform: scale(1.2);
}

#menu-module .serch-menu-container {
  width: calc(2 * (1vw + 1vh - 1vmin));
}

#menu-module .serch-menu-mobile {
  width: 5vmin;
}

#menu-module .serch-menu {
  width: calc(1.2 * (1vw + 1vh - 1vmin));
}

#menu-module .parent-hidesub:hover .tbotder {
  border-top: 2px solid #44af8e !important;
}

#menu-module .parent-hidesub a:hover {
  color: #36393d;
}

#menu-module .parent-hidesub .hide-submenu {
  visibility: hidden;
}

#menu-module .parent-hidesub:hover .hide-submenu {
  visibility: visible;
}

#menu-module .text-menu {
  font-family: "PlutoRegular";
  color: #36393d;
  font-size: 0.7vw;
  letter-spacing: 0vw;
  word-spacing: 0.3vw;
}

#menu-module .text-menu:hover {
  font-family: "PlutoBold";
  /* font-size: 0.75vw; */
}

#menu-module .button-vert-menu-mobile {
  background-color: #44af8e;
  height: calc(4 * (1vw + 1vh - 1vmin));
  border-radius: 50px;
}

#menu-module .text-menu-mobile {
  font-family: "PlutoRegular";
  color: #36393d;
  font-size: 3vw;
  letter-spacing: 0vw;
  word-spacing: 0.3vw;
}

#menu-module .menu-mobile {
  background-color: white;
  position: relative;
  top: 0;
  z-index: 120;
  width: 100%;
}

#menu-module .texte-submenu {
  font-size: 0.8vw;
  font-family: "PlutoRegular";
  color: #000000;
  float: left;
  text-align: left;
}

#menu-module .texte-submenu:hover {
  color: #44af8e;
  font-family: "PlutoBold";
}

#menu-module .bck-submenu {
  background-color: rgba(237, 222, 201, 0.9);
}

#menu-module .burguer-menu {
  display: none;
}

#menu-module .submenu {
  position: absolute;
  z-index: 200;
  right: 0;
  left: 0;
  margin-right: -20px;
  margin-left: -20px;
}

#menu-module .clicked-burguer {
  cursor: pointer;
}

#menu-module .burger-menu {
  width: 7vmin;
}

#menu-module .menu-fixed{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

#menu-module .burger-menu-close {
  display: none;
  width: 7vmin;
}

#menu-module .serch-menu-mobile-close {
  display: none;
  width: 5vmin;
}

#menu-module .menu-text-burger {
  font-size: 3vmin;
  color: #059390;
}

#menu-module .search-menu {
  display: none;
}

#menu-module .active{
  color: #36393d;
  border-bottom: 2px solid #44af8e!important;
  font-family: "PlutoBold";
}

#menu-module .submenu{
  margin-right: -40px;
  margin-left: -40px;
}
#happy-header .happy-header-size {
  height: calc(35 * (1vw + 1vh - 1vmin));
}
#happy-header .happy-header-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}


#footer-module .burguer-menu-footer {
  display: none;
}

#footer-module .list-footer-mobile li {
  font-size: 3vw;
  font-family: "PlutoRegular";
  color: #d7d7d8;
  word-spacing: 0.1vw;
  margin-top: 0.5vh;
  line-height: 2;
}

#footer-module .list-footer-mobile a {
  text-decoration: none;
  font-family: "PlutoRegular";
  color: #d7d7d8;
  word-spacing: 0.3vw;
}

#footer-module .back-footer-mobile {
  background-color: #1a212c;
}

#footer-module .list-footer a {
  text-decoration: none;
  font-family: "PlutoRegular";
  color: #d7d7d8;
  word-spacing: 0.3vw;
}

#footer-module .list-footer li {
  font-size: 0.8vw;
  font-family: "PlutoRegular";
  color: #d7d7d8;
  word-spacing: 0.1vw;
  margin-top: 0.5vh;
}

#footer-module .list-footer a:hover {
  font-family: "PlutoBold";
  font-size: 0.9vw;
}

#footer-module .back-footer {
  background-color: #2c3648;
}

#footer-module .back-footer2 {
  background-color: #1a212c;
  justify-content: flex-end;
}

#footer-module .icon-footer {
  width: 3vh;
}

#footer-module .burger-menu-footer {
  width: 7vmin;
}

#footer-module .burger-menu-footer-close {
  display: none;
  width: 7vmin;
}

#footer-module .logo {
  width: calc(8 * (1vw + 1vh - 1vmin));
  height: auto;
}
#pTechnique-module #carouselTechniques-mobile .carousel-control-next {
  right: 4vh;
  width: 30%;
}
#pTechnique-module #carouselTechniques-mobile .carousel-control-prev {
  left: 4vh;
  width: 30%;
}

#pTechnique-module .campus-pTechniques-size {
  height: calc(12 * (1vw + 1vh - 1vmin));
}
#pTechnique-module .campus-pTechniques-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}


#pTechnique-module .text-block-mobile {
  font-size: 3.5vw;
}


#pTechnique-module .text-ptechnique {
  font-size: 0.9vw;
  font-family: "PlutoLight";
}

#pTechnique-module .text-ptechnique-mobile {
  font-size: 3vw;
  font-family: "PlutoLight";
}

#pTechnique-module .ptitle {
  font-family: "Bree Serif", serif;
}

#activite-module .parent-hide .titre-icons {
  color: #afb0b1;
}

#activite-module .parent-hide:hover .titre-icons {
  color: #282828;
}

#activite-module .parent-hide .hide-menu {
  visibility: hidden;
}

#activite-module .parent-hide:hover .hide-menu {
  visibility: visible;
}

@supports (-webkit-overflow-scrolling: touch) {
   #activite-module .icon-bleuVert {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 13vmin;
  }

   #activite-module .picto-image {
    height: 9vmin;
  }

   #activite-module .parent-hide .hide-menu {
    visibility: visible;
  }
}

#activite-module .icon-bleuVert {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 19vmin;
}

#activite-module .picto-image {
  height: 15vmin;
}

#activite-module .hide-menu p {
  margin-bottom: 0.5rem;
}

#activite-module .hide-menu a {
  text-decoration: none;
}

#activite-module .texte-icons {
  font-size: 0.9vw;
  font-family: "PlutoLight";
  color: #afb0b1;
}

#activite-module .texte-icons:hover {
  color: #282828;
}

#activite-module .bck-bleuVert {
  height: 45vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

#activite-module .picto-mobile {
  width: 35vw;
}

#activite-module .display-activites {
  display: block;
}

#activite-module .titre-icons-mobile {
  font-size: 3.6vw;
  font-family: "PlutoRegular";
  color: #282828;
}

#activite-module .texte-icons-mobile {
  font-size: 2.6vw;
  font-family: "PlutoLight";
  color: #afb0b1;
}

#activite-module .texte-icons-mobile:hover {
  color: #282828;
}

#activite-module #carouselActivites-mobile a {
  text-decoration: none;
}

#activite-module #carouselActivites-mobile .carousel-control-prev {
  left: 4vh;
  width: 30%;
}

#activite-module #carouselActivites-mobile .carousel-control-next {
  right: 4vh;
  width: 30%;
}


#actualite-module.background-actualite {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#actualite-module .home-actualite-size {
  height: calc(10 * (1vw + 1vh - 1vmin));
}

#actualite-module .home-actualite-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

#actualite .card:hover .decouvrir-container {
  transform: scale(1.1);
}

#actualite-module .titres-actualites {
  font-size: 0.9vw;
  font-family: "PlutoBold";
  color: #2c3648;
  text-transform: uppercase;
}

#actualite-module .texte-actualites {
  font-size: 0.9vw;
  font-family: "PlutoRegular";
  color: #36393d;
}

#actualite-module .decouvrir {
  font-size: 0.74vw;
  font-family: "PlutoBold";
  color: #000;
  text-transform: uppercase;
  justify-content: flex-end;
}

#actualite-module .grain {
  width: auto;
  height: 11vw;
  float: right;
}

#actualite-module .titres-actualites-mobile {
  font-size: 3vw;
  font-family: "PlutoRegular";
  color: #2c3648;
  text-transform: uppercase;
}

#actualite-module .texte-actualites-mobile {
  font-size: 3vw;
  font-family: "PlutoRegular";
  color: #36393d;
}

#actualite-module .decouvrir-mobile {
  font-size: 0.74vw;
  font-family: "PlutoBold";
  color: #000;
  text-transform: uppercase;
}

/* TEXTE IMAGE DROITE */
#TID-module .tid-size {
  height: calc(30 * (1vw + 1vh - 1vmin));
}
#TID-module .tid-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

/* TEXTE IMAGE GAUCHE */
#TIG-module .tig-size {
  height: calc(30 * (1vw + 1vh - 1vmin));
}
#TIG-module .tig-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

#chiffre-module .bck-bleu {
  background-color: rgba(26, 33, 44, 0.8);
  /* height: calc(16 * (1vw + 1vh - 1vmin)); */
  border-radius: 4vh;
}

#chiffre-module .num-chiffres {
  font-size: 4vw;
  font-family: "Bree Serif", serif;
  color: #44af8e;
}
#chiffre-module .chiffres_background {
  background-image: url(/castelnaudary/img/trige-min.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#chiffre-module .num-chiffres-mobile {
  font-size: 12vw;
  font-family: "Bree Serif", serif;
  color: #44af8e;
}
#chiffre-module .text-chiffres-mobile {
  font-size: 5.4vw;
  font-family: "PlutoRegular";
  color: #fff;
  text-transform: uppercase;
}
#chiffre-module .text-chiffres {
  font-size: 1.4vw;
  font-family: "PlutoRegular";
  color: #fff;
  text-transform: uppercase;
}

#chiffre-module #campus-chiffres-mobile-block2 .carousel-control-next {
  right: 4vh;
  width: 30%;
}
#chiffre-module #campus-chiffres-mobile-block2 .carousel-control-prev {
  left: 4vh;
  width: 30%;
}

@media (max-width: 768px) {
  .titres-sitesB {
    font-size: 6vw;
  }
}
#partenaire-module .img-partenaires {
  width: calc(8 * (1vw + 1vh - 1vmin));
  height: auto;
}

#partenaire-module .campus-partenaires-size {
  height: calc(10 * (1vw + 1vh - 1vmin));
}
#partenaire-module .campus-partenaires-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

#contact-module .maps {
  height: calc(40 * (1vw + 1vh - 1vmin));
}

#contact-module .n-contacter {
  font-size: calc(4 * (1vw + 1vh - 1vmin));
  color: #354765;
  font-family: "PlutoBold";
}

#lecampus .header_background {
  background-image: url(/castelnaudary/img/arbres.png);
  height: 70vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#lecampus .ourHrB {
  background-color: #fff;
  opacity: 0.3;
  height: 3px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-left: auto;
  margin-right: auto;
}

#lecampus .mb-7 {
  margin-bottom: 10rem !important;
}
#lecampus .campus-header-size {
  height: calc(40 * (1vw + 1vh - 1vmin));
}
#lecampus .campus-header-size-mobile {
  height: calc(75 * (1vw + 1vh - 1vmin));
}

/* mobile */

#lecampus .text-block-mobile {
  font-size: 3.5vw;
}

/* responsives */

#lecampus .display-min {
  display: none;
}

@media (max-width: 768px) {
  #lecampus .display-max {
    display: none;
  }

  #lecampus .display-min {
    display: flex;
  }

  #lecampus .display-block {
    display: block;
  }
  #lecampus .titres-sitesB {
    font-size: 6.5vw;
    line-height: 1;
  }
}

#actualites .bg-main-article-text {
  background-color: rgba(44, 54, 72, 0.5);
  color: #fff;
}

#actualites .main-article-title{
    font-size: 0.74vw;
}

#actualites .main-article-text{
    font-size: 0.64vw;
}

#actualites .texte-article{
    font-size: 0.64vw;
}

#actualites .decouvrir-article {
  font-size: 0.54vw;
  font-family: "PlutoBold";
  text-transform: uppercase;
  justify-content: flex-end;
}

#actualites .decouvrir {
    font-size: 0.54vw;
    color: #354765;
    font-family: "PlutoBold";
    text-transform: uppercase;
    justify-content: flex-end;
  }

#actualites .flex-1 {
    flex: 1;
}

#actualites .next {
    width: calc(1.5 * (1vw + 1vh - 1vmin));
}


#actualites .circle-text {
    width: calc(2 * (1vw + 1vh - 1vmin));
}

#actualites .circle-text-mobile {
    width: 50%;
}

#actualites .circle-text:after,#actualites .circle-text-mobile:after  {
    content: "";
    display: block;
    width: 100%;
    height:0;
    padding-bottom: 100%;
    background: #FFFFFF; 
    -moz-border-radius: 50%; 
    -webkit-border-radius: 50%; 
    border-radius: 50%;
}
#actualites .circle-text div,#actualites .circle-text-mobile div {
    float:left;
    width:100%;
    padding-top:50%;
    line-height:1em;
    margin-top:-0.5em;
    text-align:center;
}

#actualites .title-actualite-main-left{
    color: #354765;
    text-transform: uppercase;
}

#actualites .fixed-main-height{
    height: calc(21 * (1vw + 1vh - 1vmin));
}

#actualites .article-img-size{
    height: calc(10 * (1vw + 1vh - 1vmin));
}

#actualites .title-actualite{
    color: #354765;
    text-transform: uppercase;
}

#actualites .pagination-icon-size{
    width: 2vmin;
    height: auto;
}

#actualites .pagination-text{
    color: black;
    font-size: calc(1 * (1vw + 1vh - 1vmin));
}

#actualites .pagination-active::after{
    background-color: #44af8e;
}

#actualites .pagination-active{
    color: #fff;
}

#actualites .article-img-size-mobile{
    height: calc(35 * (1vw + 1vh - 1vmin));
}

#actualites .title-actualite-mobile{
    font-size: calc(3 * (1vw + 1vh - 1vmin));
}

#actualites .description-actualite{
    font-size: calc(2 * (1vw + 1vh - 1vmin));
}

#actualites .decouvrir-article-mobile{
    font-size: calc(2 * (1vw + 1vh - 1vmin));
    background-color: #44af8e;
    border-radius: 50px;
    color: #fff
}

#actualites .pagination-text-mobile{
    color: black;
    font-size: calc(1.5 * (1vw + 1vh - 1vmin));
}

#actualites .pagination-active-mobile{
    color: #fff;
}

#actualites .pagination-active-mobile::after{
    background-color: #44af8e;
}
#actualite .main-article-image {
  height: calc(22 * (1vw + 1vh - 1vmin));
}

#actualite .titre-article {
  font-size: 1.9vw;
  font-family: "Bree Serif", serif;
  color: #354765;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

#actualite .titre-article-mobile {
  font-size: 6vw;
  font-family: "Bree Serif", serif;
  color: #354765;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

#actualite .aHR{
  background-color: #afb0b1;
  height: 1px;
}

#actualite .social-img{
    width: calc(1.5 * (1vw + 1vh - 1vmin));
    height: auto;
}

#actualite .social-img-mobile{
  width: calc(2.5 * (1vw + 1vh - 1vmin));
  height: auto;
}

#actualite .aut-date-mobile{
  font-size: calc(1.5 * (1vw + 1vh - 1vmin));
}

@media (max-width: 1024px) {
  #actualite .text-actualite{
    font-size: 2vmin
  }
}



#actualite .text-article-mobile{
  font-size: calc(2 * (1vw + 1vh - 1vmin));
}

#actualite .partext-mobile{
  font-size: 3vw;
}
#plateau-technique .wHR {
  background-color: #ffffff;
  height: 2px;
}

#plateau-technique .pl-title {
  font-size: 2vw;
  font-family: "PlutoBold";
}

#plateau-technique .pic-label {
  font-size: 1vw;
}

#plateau-technique .pl-caption {
  padding-left: calc(15 * (1vw + 1vh - 1vmin));
}

#plateau-technique .eq-container {
  min-height: calc(44 * (1vw + 1vh - 1vmin));
  padding-top: calc(10 * (1vw + 1vh - 1vmin));
  padding-bottom: calc(10 * (1vw + 1vh - 1vmin));
}

#plateau-technique .eq-title {
  font-size: 2vw;
  color: #354765;
}

#plateau-technique .ptech-container {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

#plateau-technique .oHR {
  background-color: #f79414;
  height: 2px;
}

#plateau-technique .pl-title-mobile {
  font-size: 6vw;
  font-family: "PlutoBold";
  color: #354765;
}

#plateau-technique .pic-label-mobile {
  font-size: 4vw;
}

@media (max-width: 768px) {
  #plateau-technique .carousel-indicators li {
    width: 1vmin;
    height: 1vmin;
    margin-bottom: -1vh;
    /* margin-top: 22vh; */
  }
}

#plateau-technique .ptech-text-container {
  font-size: calc(2 * (1vw + 1vh - 1vmin));
}

#plateau-technique .eq-title-mobile {
  font-size: 6vw;
  font-family: "PlutoBold";
  color: #354765;
}

#plateau-technique .eq-text-mobile {
  font-size: calc(2 * (1vw + 1vh - 1vmin));
}

#plateau-technique .eq-img-container-mobile {
    height: calc(44 * (1vw + 1vh - 1vmin));
}

#plateau-technique .carousel-control-next-icon {
  background-image: url(/castelnaudary/img/flecheb.png);
}

#plateau-technique .carousel-control-prev-icon {
  background-image: url(/castelnaudary/img/flecheb.png);
  transform: rotate(180deg);
}
#contact .lycee-logo {
  width: calc(6 * (1vw + 1vh - 1vmin));
}

#contact .lycee-logo-mobile {
  width: calc(15 * (1vw + 1vh - 1vmin));
}

#contact .gVR {
  width: 2px;
  height: 100%;
  background-color: #87d693;
}

#contact .carousel-control-next {
  right: 4vh;
  width: 30%;
}
#contact .carousel-control-prev {
  left: 4vh;
  width: 30%;
}

#contact .lycee-container {
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.2);
  border-radius: calc(1 * (1vw + 1vh - 1vmin));
}

#contact .lycee-container-mobile {
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.2);
}

#contact .lycee-name {
  font-size: calc(1 * (1vw + 1vh - 1vmin));
  font-family: "PlutoBold";
}

#contact .lycee-name-mobile {
  font-size: calc(3 * (1vw + 1vh - 1vmin));
  color: #354765;
  font-family: "PlutoBold";
}

#contact .lycee-texts-mobile {
  font-size: calc(1.5 * (1vw + 1vh - 1vmin));
}


@media (max-width: 768px) {
  #contact .c-m-h-hr {
    display: none;
  }

  #contact .c-m-h-t{
    text-align: center!important;
    margin-top : calc(1.5 * (1vw + 1vh - 1vmin)) !important;
  }

  #contact .c-m-c{
    display: flex !important;
    flex-direction: column !important;
  }
}



#cadreDeVie .tab-img-size {
  height: calc(30 * (1vw + 1vh - 1vmin));
}

#cadreDeVie .cdv-img-size {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

#cadreDeVie .legende {
  font-size: 0.8vw;
  font-family: "PlutoLight";
  color: #36393d;
  word-spacing: 0.3vw;
  font-style: italic;
}

#cadreDeVie .legende-mobile {
  font-family: "PlutoLight";
  color: #36393d;
  word-spacing: 0.3vw;
  font-style: italic;
}
#cadreDeVie .titre-tab {
  font-size: 1vw;
  font-family: "PlutoRegular";
  color: #36393d;
  text-transform: uppercase;
  word-spacing: 0.3vw;
  opacity: 0.4 !important;
}

#cadreDeVie tabs .nav-link.active,
#cadreDeVie .nav-item:hover .titre-tab {
  font-size: 1.1vw;
  font-family: "PlutoBold";
}

#cadreDeVie .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-size: 1.1vw;
  margin-top: -7px;
  font-family: "PlutoBold";
  opacity: 1 !important;
}

#cadreDeVie .nav-tabs .nav-link {
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#cadreDeVie .nav-tabs {
  border-bottom: none;
}

#cadreDeVie .rborder {
  border-right: 1px groove rgba(54, 57, 61, 0.4) !important;
}

#cadreDeVie .tab-opacite {
  opacity: 0.4;
}

#cadreDeVie .form-control {
  border: 2px solid #464646;
  border-radius: 2rem;
  color: #464646;
}

#cadreDeVie .svg-arrow {
  appearance: none;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 1em;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 612 612" fill="rgba(54, 57, 61, 0.4)"><path d="M 96.00,96.00l-96.00,96.00l 256.00,256.00l 256.00-256.00l-96.00-96.00L 256.00,256.00L 96.00,96.00z"/></svg>');
  background-position-x: 94%;
  text-align: center;
  text-align-last: center;
}

#cadreDeVie .video-size {
  width: 100%;
  height: calc(37 * (1vw + 1vh - 1vmin));
}

#cadreDeVie .video-size-mobile {
  width: 100%;
  height: 500px;
}

#cadreDeVie #cdv-etablissement.display-max .carousel-control-next-icon, #cadreDeVie #cdv-etablissement.display-max .carousel-control-prev-icon {
  height: calc(4 * (1vw + 1vh - 1vmin));
  width: calc(1 * (1vw + 1vh - 1vmin));
}

.vde-masque {
  display: none;
}

#sousformation .formation-image {
  height: calc(25 * (1vw + 1vh - 1vmin));
}
#sousformation .formation-image-mobile {
  height: calc(32 * (1vw + 1vh - 1vmin));
}

#sousformation .sousTitre-formation {
  font-family: "PlutoBold";
  font-size: 1.5vw;
  color: #354765;
}

#sousformation .accordion-title {
  font-family: "PlutoBold";
  font-size: 1vw;
  color: inherit;
  text-decoration: none;
}

#sousformation .accordion-title-container {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

#sousformation .minus {
  width: calc(1 * (1vw + 1vh - 1vmin));
}

#sousformation .plissus {
  width: calc(1 * (1vw + 1vh - 1vmin));
}

#sousformation .hide-accordion-picto {
  display: none;
}

#sousformation .CTA-container {
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.2);
  border-radius: calc(1 * (1vw + 1vh - 1vmin));
  padding: calc(3 * (1vw + 1vh - 1vmin));
  margin-right: calc(2 * (1vw + 1vh - 1vmin));
  margin-left: calc(2 * (1vw + 1vh - 1vmin));
}

#sousformation .background-temoignage {
  background-color: #eddec9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#sousformation .background-telechargement {
  background-color: rgba(68, 175, 142, 0.2);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#sousformation .background-calendrier {
  background-image: url(/castelnaudary/img/calendrie.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#sousformation .temoignage-bg{
  border-radius: 50%;
  height: 17vmin;
  width: 17vmin;
}

#sousformation .temoignage-img-container{
  justify-content: flex-end;
}

#sousformation .temoignage-title {
  font-family: "PlutoBold";
  font-size: 1.2vw;
  color: #6c6d6f;
  text-decoration: none;
  text-transform: uppercase;
}

#sousformation .temoignage-nom {
  font-family: "Bree Serif", serif;
  font-size: 1.5vw;
  color: #354765;
}
#sousformation .temoignage-text {
  font-family: "PlutoRegular";
  font-size: 1vw;
  color: #6c6d6f;
  text-decoration: none;
}


#sousformation #carouselTemoignage .carousel-control-next {
  right: 4vh;
  width: 30%;
}
#sousformation #carouselTemoignage .carousel-control-prev {
  left: 4vh;
  width: 30%;
}

#sousformation #carouselContFormation .carousel-control-next {
  right: 1vh;
  width: 30%;
}
#sousformation #carouselContFormation .carousel-control-prev {
  left: 1vh;
  width: 30%;
}

#sousformation #carouselTemoignage-mobile .carousel-control-next {
  right: 4vh;
  width: 30%;
}
#sousformation #carouselTemoignage-mobile .carousel-control-prev {
  left: 4vh;
  width: 30%;
}


#sousformation .md-block {
  display: none;
}

@media (max-width: 768px) {
  #sousformation .md-block {
    display: block !important;
  }
}

#sousformation .accordion-title-mobile {
  font-family: "PlutoBold";
  font-size: 3.2vw;
  color: inherit;
  text-decoration: none;
}
#sousformation .minus-mobile {
  width: calc(3 * (1vw + 1vh - 1vmin));
}
#sousformation .plissus-mobile {
  width: calc(3 * (1vw + 1vh - 1vmin));
}

#sousformation .temoignage-img-container-mobile {
  justify-content: center;
}

#sousformation .temoignage-bg-mobile {
  border-radius: 50%;
  height: 25vmin;
  width: 25vmin;
}

#sousformation .temoignage-title-mobile {
  font-family: "PlutoBold";
  font-size: 3.5vw;
  color: #6c6d6f;
  text-decoration: none;
  text-transform: uppercase;
}

#sousformation .temoignage-nom-mobile {
  font-family: "Bree Serif", serif;
  font-size: 6.5vw;
  color: #354765;
}

#sousformation .temoignage-text-mobile {
  font-family: "PlutoRegular";
  font-size: 3vw;
  color: #6c6d6f;
  text-decoration: none;
}

#sousformation .CTA-container-mobile {
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.2);
  border-radius: calc(6 * (1vw + 1vh - 1vmin));
  padding: calc(3 * (1vw + 1vh - 1vmin));
  margin-right: calc(2 * (1vw + 1vh - 1vmin));
  margin-left: calc(2 * (1vw + 1vh - 1vmin));
}

#sousformation .carousel-control-next-icon, #sousformation .carousel-control-prev-icon {
  height: calc(2* (1vw + 1vh - 1vmin));
  width: calc(2* (1vw + 1vh - 1vmin));
}



#mentionslegales.height-page{
    min-height: 100vh;
}

#mentionslegales .flex-mention-1{
    flex-grow: 1;
}
#quatrecent4 .quatrecent4-size{
    height: 25vh;
}

#quatrecent4.height-page{
    height: 100vh;
}
#pagenotfound .pagenotfound-size{
    height: 25vh;
}

#pagenotfound.height-page{
    height: 100vh;
}
#formationcontinue .happy-header-size {
    height: calc(20 * (1vw + 1vh - 1vmin));
  }
#formationcontinue .happy-header-size-mobile {
    height: calc(25 * (1vw + 1vh - 1vmin));
}

#formationcontinue .formation-img-size {
    height: calc(16 * (1vw + 1vh - 1vmin));
}

#formationcontinue .formation-img-size-mobile {
    height: calc(23 * (1vw + 1vh - 1vmin));
}

#formationcontinue .text-formation{
    font-size: 1vw;
}

#formationcontinue .text-formation-mobile{
    font-size: 3.1vw;
}


#formationcontinue #carouselTechniques-mobile .carousel-control-next {
right: 2vh;
width: 30%;
}

#formationcontinue #carouselTechniques-mobile .carousel-control-prev {
left: 2vh;
width: 30%;
}
#questionReponse .ofspace {
  margin: 0;
  padding: 0;
}

#questionReponse .hide-form {
  display: none;
}

#questionReponse .hide-solution {
  display: none;
}

#questionReponse .bar-container-active {
  z-index: 0;
  position: absolute;
  height: 7px;
  width: 100%;
  background-color: #44af8e;
  -webkit-box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.21);
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#questionReponse .circle-container-active {
  z-index: 2;
  height: 52px;
  width: 52px;
  background-color: rgba(68, 175, 142, 0.5);
  border-radius: 50%;
}

#questionReponse .circle-number-active {
  z-index: 1;
  -webkit-box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.21);
  height: 42px;
  width: 42px;
  background-color: #44af8e;
  border-radius: 50%;
}

#questionReponse .step-number-active {
  z-index: 3;
  color: #fff;
  font-family: "PlutoBold";
  font-size: 24px;
  font-weight: 700;
}

#questionReponse .circle-container {
  z-index: 1;
  height: 52px;
  width: 52px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

#questionReponse .bar-container {
  z-index: 0;
  position: absolute;
  height: 7px;
  width: 100%;
  background-color: #a7a8aa;
  opacity: 0.5;
  -webkit-box-shadow: 0 0 27px 0 transparent;
  box-shadow: 0 0 27px 0 transparent;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#questionReponse .circle-number {
  z-index: 2;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
}

#questionReponse .step-number {
  color: #36393d;
  font-family: "PlutoRegular";
  font-size: 18px;
  font-weight: 700;
}
#questionReponse .bck-size {
  height: calc(31 * (1vw + 1vh - 1vmin));
}

#questionReponse .bck-size-mobile {
  height: calc(75 * (1vw + 1vh - 1vmin));
}

#questionReponse.height-page {
  min-height: 100vh;
}

#questionReponse .flex-mention-1 {
  flex-grow: 1;
}

#questionReponse .backgroud-white {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4vh;
}

#questionReponse .question {
  font-size: 1.3vw;
  font-family: "Bree Serif", serif;
  color: #2c3648;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

#questionReponse .question-mobile {
  font-size: 6vw;
  font-family: "Bree Serif", serif;
  color: #2c3648;
  text-transform: uppercase;
  word-spacing: 0.3vw;
}

#questionReponse .repnc {
  cursor: pointer;
}

#questionReponse .circle-container-radio {
  height: 20px;
  width: 20px;
  background-color: #c1c1c1;
  border: 2px solid #c1c1c1;
  border-radius: 50%;
}

#questionReponse .circle-radio,
.circle-radio-checked {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
#questionReponse .circle-radio-checked {
  background-color: #44af8e;
}

#questionReponse .ques-resp-checked {
  color: #36393d;
  font-family: "PlutoBold";
  font-size: 1vw;
}

#questionReponse .ques-resp-checked-mobile {
  color: #36393d;
  font-family: "PlutoBold";
  font-size: 3vw;
}
#questionReponse .ques-resp {
  color: #36393d;
  font-family: "PlutoRegular";
  font-size: 1vw;
}

#questionReponse .ques-resp-mobile {
  color: #36393d;
  font-family: "PlutoRegular";
  font-size: 3vw;
}

#questionReponse [type="radio"]:checked,
#questionReponse [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
#questionReponse [type="radio"]:checked + label,
#questionReponse [type="radio"]:not(:checked) + label {
  padding-left: 3vw;
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
#questionReponse [type="radio"]:checked + label:before,
#questionReponse [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  border-radius: 100%;
  background: #fff;
}
#questionReponse .radio input[type="radio"]:checked + .radio-label:before {
  background-color: #44af8e;
  box-shadow: inset 0 0 0 3.5px #fff;
  color: #36393d;
  font-family: "PlutoBold";
}
#questionReponse .radio input[type="radio"]:checked + .radio-label {
  color: #36393d;
  font-family: "PlutoBold";
}
#questionReponse .radio input[type="radio"]:checked + .radio-label-mobile {
  color: #36393d;
  font-family: "PlutoBold";
}
#questionReponse
  .radio
  input[type="radio"]:checked
  + .radio-label-mobile:before {
  background-color: #44af8e;
  box-shadow: inset 0 0 0 3px #fff;
}
#questionReponse [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
#questionReponse [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 768px) {
  #questionReponse [type="radio"]:checked + label,
  #questionReponse [type="radio"]:not(:checked) + label {
    padding-left: 8vw;
  }
}

#questionReponse.height-page {
  min-height: 100vh;
}

#questionReponse .flex-mention-1 {
  flex-grow: 1;
}

#questionReponse .happy-header-size {
  height: calc(20 * (1vw + 1vh - 1vmin));
}
#questionReponse .happy-header-size-mobile {
  height: calc(25 * (1vw + 1vh - 1vmin));
}

#questionReponse .formation-img-size {
  height: calc(16 * (1vw + 1vh - 1vmin));
}

#questionReponse .formation-img-size-mobile {
  height: calc(23 * (1vw + 1vh - 1vmin));
}

#questionReponse .text-formation {
  font-size: 1vw;
}

#questionReponse .text-formation-mobile {
  font-size: 3.1vw;
}

#questionReponse #carouselTechniques-mobile .carousel-control-next {
  right: 2vh;
  width: 30%;
}

#questionReponse #carouselTechniques-mobile .carousel-control-prev {
  left: 2vh;
  width: 30%;
}

#centreEquestre .happy-header-size {
    height: calc(20 * (1vw + 1vh - 1vmin));
  }
#centreEquestre .happy-header-size-mobile {
    height: calc(25 * (1vw + 1vh - 1vmin));
}

#centreEquestre .formation-img-size {
    height: calc(16 * (1vw + 1vh - 1vmin));
}

#centreEquestre .formation-img-size-mobile {
    height: calc(23 * (1vw + 1vh - 1vmin));
}

#centreEquestre .text-formation{
    font-size: 1vw;
}

#centreEquestre .text-formation-mobile{
    font-size: 3.1vw;
}


#centreEquestre #carouselTechniques-mobile .carousel-control-next {
right: 2vh;
width: 30%;
}

#centreEquestre #carouselTechniques-mobile .carousel-control-prev {
left: 2vh;
width: 30%;
}
#quisommesnous .tid-size {
  height: calc(30 * (1vw + 1vh - 1vmin));
}
#quisommesnous .tid-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}
#quisommesnous .signature-img {
  height: calc(5 * (1vw + 1vh - 1vmin));
}
#quisommesnous .signature-img-mobile {
  height: calc(15 * (1vw + 1vh - 1vmin));
}

#quisommesnous .number-center {
    font-size: 1.8vw;
    color:#fff;
    font-family: "Bree Serif", serif;
}

#quisommesnous .text-missions{
    font-size: .9vw;
    color:#6c6d6f;
    font-family: "PlutoRegular";
}
#quisommesnous .text-missions-mobile{
    font-size: 3vw;
    color:#6c6d6f;
    font-family: "PlutoRegular";
}
#quisommesnous .parent-hide .titre-icons {
    color: #afb0b1;
  }
  
  #quisommesnous .parent-hide:hover .titre-icons {
    color: #282828;
  }
  
  #quisommesnous .parent-hide .hide-menu {
    visibility: hidden;
  }
  
  #quisommesnous .parent-hide:hover .hide-menu {
    visibility: visible;
  }
  
  @supports (-webkit-overflow-scrolling: touch) {
     #quisommesnous .icon-bleuVert {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 6vmin;
    }
  
     #quisommesnous .picto-image {
      height: 6vmin;
    }
  
     #quisommesnous .parent-hide .hide-menu {
      visibility: visible;
    }
  }
  
  #quisommesnous .icon-bleuVert {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 8vmin;
  }
  
  #quisommesnous .picto-image {
    height: 8vmin;
  }
  
  #quisommesnous .hide-menu p {
    margin-bottom: 0.5rem;
  }
  
  #quisommesnous .hide-menu a {
    text-decoration: none;
  }
  
  #quisommesnous .texte-icons {
    font-size: 0.9vw;
    font-family: "PlutoLight";
    color: #afb0b1;
  }
  
  #quisommesnous .texte-icons:hover {
    color: #282828;
  }
  
  #quisommesnous .bck-bleuVert {
    height: 45vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  
  #quisommesnous .picto-mobile {
    width: 35vw;
  }
  
  #quisommesnous .display-activites {
    display: block;
  }
  
  #quisommesnous .titre-icons-mobile {
    font-size: 3.6vw;
    font-family: "PlutoRegular";
    color: #282828;
  }
  
  #quisommesnous .texte-icons-mobile {
    font-size: 2.6vw;
    font-family: "PlutoLight";
    color: #afb0b1;
  }
  
  #quisommesnous .texte-icons-mobile:hover {
    color: #282828;
  }
  
  #quisommesnous #carouselActivites-mobile a {
    text-decoration: none;
  }
  
  #quisommesnous #carouselActivites-mobile .carousel-control-prev {
    left: 4vh;
    width: 30%;
  }
  
  #quisommesnous #carouselActivites-mobile .carousel-control-next {
    right: 4vh;
    width: 30%;
  }

 #quisommesnous .circle-number {
    z-index: 1;
    height: 6vmin;
    width: 6vmin;
    background-color: #fdcd7b;
    border-radius: 50%;
  }
 #quisommesnous .step-number {
    z-index: 2;
    font-size: 1.5vw;
    color:#fff;
    font-family: "Bree Serif", serif;
  }
 #quisommesnous .ofspace {
    margin: 0;
    padding: 0;
  }
  
  #quisommesnous .circle-number-mobile {
    z-index: 1;
    height: 27vmin;
    width: 27vmin;
    background-color: #fdcd7b;
    border-radius: 50%;
}

#quisommesnous .step-number-mobile {
    z-index: 2;
    font-size: 8.5vw;
    color: #fff;
    font-family: "Bree Serif", serif;
}
#informationspratiques .happy-header-size {
  height: calc(35 * (1vw + 1vh - 1vmin));
}
#informationspratiques .happy-header-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

#informationspratiques .block-ext {
  background-color: #fff;
  border-radius: 50px;
  -webkit-box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
}

#informationspratiques .border-int {
  border: 1.5px solid #eddec9;
  border-radius: 40px;
  background-color: #fff;
}

#informationspratiques .position-block {
  margin-top: -10vw;
}

#informationspratiques .texte-horaires {
  font-size: 1.8vw;
  font-family: "Bree Serif", serif;
  color: #384a67;
  text-transform: uppercase;
}

#informationspratiques .texte-jours {
  font-size: 1vw;
  font-family: "PlutoRegular";
  color: #36393d;
  text-transform: uppercase;
}

#informationspratiques .texte-heures {
  font-size: 0.8vw;
  font-family: "PlutoRegular";
  color: #36393d;
}

#informationspratiques .ourHr {
  background-color: #afb0b1;
  height: 1px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.5;
}

#informationspratiques .block-ext-mobile {
  background-color: #fff;
  border-radius: 40px;
  -webkit-box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
}

#informationspratiques .border-int-mobile {
  border: 1.5px solid #eddec9;
  border-radius: 30px;
  background-color: #fff;
}
#informationspratiques .texte-horaires-mobile {
  font-size: 4.2vw;
  font-family: "Bree Serif", serif;
  color: #384a67;
  text-transform: uppercase;
}

#informationspratiques .texte-jours-mobile {
  font-size: 2.5vw;
  font-family: "PlutoRegular";
  color: #36393d;
  text-transform: uppercase;
}

#informationspratiques .texte-heures-mobile {
  font-size: 2vw;
  font-family: "PlutoRegular";
  color: #36393d;
}

#informationspratiques .ourHr-mobile {
  background-color: #afb0b1;
  height: 1px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.5;
}

#informationspratiques .position-block-mobile {
  position: relative;
  top: -35vw;
}
#informationspratiques .md-block {
  display: none;
}

@media (max-width: 768px) {
  #informationspratiques .md-block {
    display: block !important;
  }
}

#informationspratiques .texts-tarifs {
  font-size: 1.3vw;
  font-family: "PlutoRegular";
  color: #36393d;
}
#informationspratiques .texts-tarifs-mobile {
  font-size: 3vw;
  font-family: "PlutoRegular";
  color: #36393d;
}

#informationspratiques .prix-tarifs {
  font-size: 1.3vw;
  font-family: "PlutoRegular";
  color: #44af8e;
  text-transform: uppercase;
}

#informationspratiques .prix-tarifs-mobile {
  font-size: 3vw;
  font-family: "PlutoRegular";
  color: #44af8e;
  text-transform: uppercase;
}

#informationspratiques .b-border {
  border-bottom: 1px solid #afb0b1;
}

#informationspratiques #carouselTechniques-mobile .carousel-control-next {
  right: 4vh;
  width: 30%;
}
#informationspratiques #carouselTechniques-mobile .carousel-control-prev {
  left: 4vh;
  width: 30%;
}

#informationspratiques .campus-pTechniques-size {
  height: calc(13 * (1vw + 1vh - 1vmin));
  width: calc(13 * (1vw + 1vh - 1vmin));
}
#informationspratiques .campus-pTechniques-size-mobile {
  height: calc(24 * (1vw + 1vh - 1vmin));
}

#informationspratiques .text-block-mobile {
  font-size: 3.5vw;
}

#informationspratiques .text-ptechnique {
  font-size: 0.9vw;
  font-family: "PlutoLight";
}

#informationspratiques .text-ptechnique-mobile {
  font-size: 3vw;
  font-family: "PlutoLight";
}

#informationspratiques .background-actualite {
  background-image: url(/castelnaudary/img/bck-creme.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#informationspratiques .background-jpo {
  background-image: url(/castelnaudary/img/ecolet.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#informationspratiques .background-cheval {
    background-image: url(/castelnaudary/img/cheval1.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }

#informationspratiques .cheval {
  width: auto;
  height: 34vw;
  /* float: right; */
}

#informationspratiques .cheval-size {
  height: calc(28 * (1vw + 1vh - 1vmin));
}
#informationspratiques .test-size {
  height: calc(28 * (1vw + 1vh - 1vmin));
}
#informationspratiques .test-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

#informationspratiques .tid-size {
  height: calc(30 * (1vw + 1vh - 1vmin));
}
#informationspratiques .tid-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

.nf-field-label {
  position: absolute;
  z-index: 100;
  right: 0;
  margin-right: 1%;
  margin-top: 2%;
  width: inherit;
}
input:not([type="button"]).ninja-forms-field {
  background: transparent !important;
  border: #fff !important;
  border-bottom: 1px solid #cccccc !important;
}
textarea.ninja-forms-field {
  background: transparent !important;
  border: #fff !important;
  border-bottom: 1px solid #cccccc !important;
}
select.ninja-forms-field {
  background: transparent !important;
  border: #fff !important;
  border-bottom: 1px solid #cccccc !important;
}
.nf-form-content .list-select-wrap .nf-field-element > div {
  background: transparent !important;
  border: none !important;
}

@media (min-width: 768px) {
  .form-space{
    margin-left: 8vmax;
    margin-right: 8vmax;
    padding-left: 2vmax;
    padding-right: 2vmax;
  }
}

.form-space .titres-sites{
  font-size: 1.9vw;
}